import { defineStore } from "pinia";

export const useUsersStore = defineStore("user", {
  state: () => {
    return {
      theme: "Coo", // Snailax //Comfier //Cupilo //Coo
      defaultLang: "en", //系统默认语言
      isLogin: localStorage.getItem("token") ? true : false,
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      account: "",
      userAddress: "",
      activationCode: "",
      logisticsToken: localStorage.getItem("logisticsToken")
        ? localStorage.getItem("logisticsToken")
        : "",
    };
  },
});
